import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { graphql, useStaticQuery } from "gatsby";

import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Layout/components/Section/Section";
import { ServiceCategory } from "../components/ServiceCategory";
import { useServiceCategories } from "./hooks/useServiceCategories";

export interface ServicesCategoriesProps {
  className?: string;
}

const ServicesCategoriesInner = (props: ServicesCategoriesProps) => {
  const categories = useServiceCategories();
  return (
    <Section className={props.className}>
      <Container>
        <Grid container justify="center" alignItems="center" spacing={4}>
          {categories.map(category => (
            <Grid item lg={3} md={6} xs={12} key={category.title}>
              <ServiceCategory {...category} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export const ServicesCategories = styled(ServicesCategoriesInner)``;
