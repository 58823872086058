import { useServiceCategoryImages } from "./useServiceCategoryImages";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ServiceCategoryProps } from "../../components/ServiceCategory";
import slugify from "slugify";

export const useServiceCategories = (): ServiceCategoryProps[] => {
  const { t } = useTranslation("translations");
  const images = useServiceCategoryImages();
  console.log("yo", images);

  const getServiceCategoryDataFromTranslations = (serviceCategory: string) => {
    const servicesTitle = t(`services.title`);
    const categoryTitle = t(`services.${serviceCategory}.title`);
    return {
      title: categoryTitle,
      image: images[serviceCategory].childImageSharp.fluid,
      shortText: t(`services.${serviceCategory}.description`),
      buttonText: t(`services.${serviceCategory}.button`),
      buttonUrl: `/sluzby/${slugify(categoryTitle, { lower: true })}`
    };
  };

  return [
    getServiceCategoryDataFromTranslations("coaching"),
    getServiceCategoryDataFromTranslations("therapy"),
    getServiceCategoryDataFromTranslations("leadership"),
    getServiceCategoryDataFromTranslations("workshops")
  ];
};
