import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import GatsbyImage, { FluidObject } from "gatsby-image";
import React from "react";
import styled from "styled-components";
import { Button } from "../../Shared/Button/Primary/Button";

export interface ServiceCategoryProps {
  className?: string;
  title?: string;
  shortText?: string;
  buttonText?: string;
  buttonUrl?: string;
  image: FluidObject;
}

const ServiceCategoryInner = (props: ServiceCategoryProps) => {
  const { title, shortText, buttonText, buttonUrl, image } = props;
  return (
    <article className={props.className}>
      <ImageWrapper>
        <GatsbyImage fluid={image} />
      </ImageWrapper>
      <TextWrapper>
        <ShortTextWrapper>
          <h3>{title}</h3>
          <p>{shortText}</p>
        </ShortTextWrapper>

        <Link to={buttonUrl}>
          <Button type="primary">{buttonText}</Button>
        </Link>
      </TextWrapper>
    </article>
  );
};

export const ServiceCategory = styled(ServiceCategoryInner)`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  max-height: 100%;
  height: 190px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 240px;
  z-index: 10;
  position: relative;
  margin-top: -30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ShortTextWrapper = styled.div`
  background-color: ${(props) => props.theme.color.white};
  margin: 0 20px;
  border-radius: 10px;
  padding: 20px 40px;
  width: 60%;
  text-align: center;

  display: flex;
  min-height: 104px;
  flex-flow: column;
  justify-content: center;

  h3 {
    margin-bottom: 10px;
    font-size: 20px;
  }
`;
