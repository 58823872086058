import Container from "@material-ui/core/Container";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Section } from "../Shared/Layout/components/Section/Section";
import { CustomAccordionProps, ServiceItem } from "./components/ServiceItem";

export interface ServicesItemsProps {
  className?: string;
}

const ServicesItemsInner = (props: ServicesItemsProps) => {
  const { t } = useTranslation("translations");
  const serviceItems = [
    {
      title: t("services.coaching.title"),
      text: (
        <>
          <p>
            Ako Váš „Lodivod“ využívam pri práci najmä Brief Coaching a Solution
            Focused coaching, ktorý podnecuje rozmýšľať o možných riešeniach
            oveľa viac než o problémoch a prekážkach. Pracujem spôsobom, aby ma
            klient čo najskôr prestal potrebovať. Často nám stačia 1 až 3
            stretnutia.
          </p>
          <p>
            Neanalyzujem problém ani „ťaživú minulosť“, nerobím diagnostiku. Som
            zameraný na želanú budúcnosť
          </p>
          <p>
            Koučujem hravo a odvážne. Preferujem autentický prístup k metóde,
            k výberu slov, k človeku. Toto svoje nastavenie nepoužívam „iba“
            v práci, ale žijem ho.
          </p>
          <p>
            Držím sa hesla, že „ najrýchlejšie si spomenieš na to, čo si sám
            vymyslel.“ Verím, že len vlastným preskúmavaním a objavením „svojich
            právd“ môže človek získať stabilitu v živote. Bude sa tak môcť
            v čase, keď to bude potrebovať , oprieť sám o seba.
          </p>
        </>
      ),
      accordion: t("services.coaching.faqAccordion", {
        returnObjects: true,
      }) as CustomAccordionProps[],
    },
    {
      title: t("services.therapy.title"),
      text: (
        <>
          <p>
            Na rozdiel od tradičných foriem terapie, ktoré si vyžadujú čas na
            analýzu problémov, patológií minulých životných udalostí,
            Solution-Focused Brief Therapy (na riešenie orientovaná krátka
            terapia) sa sústreďuje na hľadanie riešení v súčasnosti a skúmanie
            nádeje klienta do budúcnosti, tak aby bolo možné rýchlejšie vyriešiť
            problémy.
          </p>
        </>
      ),
      accordion: t("services.therapy.faqAccordion", {
        returnObjects: true,
      }) as CustomAccordionProps[],
    },
    {
      title: t("services.leadership.title"),
      text: (
        <>
          <p>
            Využívame užitočné informácie, príklady a skúsenosti z vlastnej
            manažérskej  a obchodníckej praxe na efektívny rozvoj manažérov a
            obchodníkov.
          </p>
          <p>
            V programoch kombinujeme rôzne techniky (koučing, mentoring,
            tréningy a poradenstvo) podľa odvetvia, naliehavosti problémov a
            zvolených cieľov.
          </p>
          <p>
            Školenia a semináre sú zamerané na konkrétne situácie a oblasti, v
            ktorých ľuďom vo Vašej spoločnosti chýba sebadôvera a odhodlanie.
          </p>
          <p>
            Obsah prispôsobím oblastiam Vášho podnikania a pomocou praktických
            príkladov ukážem Vašim zamestnancom overené postupy, ktoré premenia
            slabé stránky na nové zručnosti.
          </p>
        </>
      ),
      accordion: t("services.leadership.faqAccordion", {
        returnObjects: true,
      }) as CustomAccordionProps[],
    },
    {
      title: t("services.workshops.title"),
      text: (
        <>
          <p>
            ...často krát sa stáva, že nám nechýbajú ďalšie vedomosti
            a zručnosti. Dokonca sme veľa krát „pre-kvalifikovaní“ a o danej
            oblasti máme dostatočné množstvo informácií. To čo nás prijme konať
            a pohnúť sa z miesta, je schované niekde inde : v našom pohľade na
            vec, v našom postoji, v našej vôli, v našom odhodlaní, v našom
            dostatku alebo nedostatku energie, v našej radosti ... Na prácu
            s týmito faktormi sa zameriavam v inšpiratívnom vzdelávaní.
          </p>
          <p>
            Nie je mojou snahou vystupovať v daných témach ako „pravdu-majúca
            autorita“ . Naopak. Vytváram priestor pre vlastný názor ako aj pre
            prípadný nesúhlas. Kladiem dôraz na to, aby som ponechal účastníkom
            možnosť – samým objaviť “svoje pravdy” , a to prostredníctvom
            vlastného zážitku.
          </p>
        </>
      ),
      accordion: t("services.workshops.faqAccordion", {
        returnObjects: true,
      }) as CustomAccordionProps[],
    },
  ];
  return (
    <Section>
      <Container>
        {serviceItems.map((item) => (
          <ServiceItem key={item.title} {...item} />
        ))}
      </Container>
    </Section>
  );
};

export const ServicesItems = styled(ServicesItemsInner)``;
