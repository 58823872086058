import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Shared/Layout/Layout";
import { ServicesCategories } from "../components/Services/ServicesCategories/ServicesCategories";
import { TopHeader } from "../components/Shared/Layout/components/TopHeader/TopHeader";
import { TextSection } from "../components/Shared/Layout/components/TextSection/TextSection";
import { ServicesItems } from "../components/Services/ServicesItems";
import { FormSection } from "../components/Shared/FormSection/FormSection";

const ServicesPage = () => (
  <Layout>
    <TopHeader title="Služby" />
    <TextSection
      title="Prečo investovať do seba?"
      description="Bez ohľadu na to, či ste generálnym riaditeľom vo veľkej spoločnosti, malým podnikateľom, športovcom alebo máte problémy v súkromnom živote, kvalita vášho mentálneho sveta ovplyvňuje vašu prácu, vaše vzťahy, vašu rodinu a vaše šťastie. Poďme spolu preskúmať, akú kvalitu chcete dosiahnuť."
    />
    <ServicesCategories />
    <ServicesItems />
    <FormSection />
  </Layout>
);

export default ServicesPage;
