import { Accordion } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import React from "react";
import { ReactNode } from "react";
import ReactMarkdown from "react-markdown";
import slugify from "slugify";
import styled from "styled-components";
import { CustomAccordionsProps } from "../../Home/LetsStartSection/components/RightSide/CustomAccordions";
import { ViewMoreButton } from "../../Shared/Button/ViewMore/ViewMoreButton";
import { CustomAccordion } from "../../Shared/CustomAccordion/CustomAccordion";

export interface CustomAccordionProps {
  question: string;
  answer: string;
}

export interface ServiceItemProps {
  title: string;
  text: ReactNode;
  accordion: CustomAccordionProps[];

  className?: string;
}

const TitleSection = styled.div`
  display: flex;
`;

const ServiceItemInner = (props: ServiceItemProps) => {
  const { accordion, title, text } = props;

  return (
    <div className={props.className}>
      <Grid container spacing={5}>
        <Grid
          item
          sm={6}
          xs={12}
          container
          alignContent="flex-start"
          justify="space-between"
        >
          <Title>{title}</Title>
          {text}
          <ViewMoreButton
            style={{
              marginTop: 10
            }}
            to={`/sluzby/${slugify(title, { lower: true })}`}
          >
            Zobraziť viac
          </ViewMoreButton>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div
            style={{
              marginTop: 35
            }}
          >
            {accordion.map(acc => (
              <CustomAccordion
                key={acc.question}
                className="whiteBG"
                title={acc.question}
                markdownSource={acc.answer}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const Title = styled.h2`
  margin-bottom: 20px;
`;

export const ServiceItem = styled(ServiceItemInner)`
  margin-bottom: 45px;

  p {
    margin-bottom: 10px;
  }
`;
